import React from 'react'
import ProjectItem from '../components/Projectitem'

const ProjectList = ({ projects }) => {
  const even = projects.length % 2 === 0
  return (
    <ul
      className={even ? 'projectlist extra' : 'projectlist'} // If amount of projects is even, add extra class
    >
      {projects.map(({ project }) => {
        return (
          <ProjectItem
            content={project.document.data}
            slug={`/projects/${project.uid}`}
            key={project.uid}
          />
        )
      })}
    </ul>
  )
}

export default ProjectList
