import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'

const ProjectItem = ({ content, slug }) => {
  const [mounted, setMounted] = useState(false)
  const { ref, inView } = useInView({
    threshold: 0,
  })
  useEffect(() => {
    const timer = setTimeout(() => {
      setMounted(true)
    }, 100)
    return () => clearTimeout(timer)
  })
  return (
    <li
      className={mounted && !inView ? `projectitem not-in-view` : `projectitem`}
      ref={ref}
    >
      <Link to={slug}>
        {content.index_image.url ? (
          <div className="wrapper">
            <img
              alt=""
              // width="688"
              // height="390"
              src={`${content.index_image.url}&w=344&h=195`}
              srcSet={`
          ${content.index_image.url}&w=344&h=195 344w,
          ${content.index_image.url}&w=688&h=390 688w,
          ${content.index_image.url}&w=1376&h=780 1376w,
          ${content.index_image.url}&w=2752&h=1560 2752w
          `}
              sizes={`
          (max-width: 699px) calc(100vw - 36px),
          (max-width: 999px) calc((100vw - 108px) / 2),
          (max-width: 1544px) calc((100vw - 216px) / 2),
          664px
          `}
            />
          </div>
        ) : null}
        <h2>{content.title.text}</h2>
      </Link>
    </li>
  )
}

export default ProjectItem
