import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import CallToAction from '../components/CTA'
import ProjectList from '../components/ProjectList'
import Meta from '../components/Meta'
import './index.scss'

const WorkPage = ({ data, location }) => {
  return (
    <Layout>
      <Meta
        data={{
          pathName: location.pathname,
          title: 'Work by Studio WillemsPeeters',
        }}
      />
      <div className="container">
        <ProjectList projects={data.allPrismicIndex.edges[0].node.data.work} />
        <CallToAction work />
      </div>
    </Layout>
  )
}

export default WorkPage

export const query = graphql`
  query workQuery {
    allPrismicIndex {
      edges {
        node {
          data {
            work {
              project {
                uid
                document {
                  ... on PrismicWork {
                    id
                    data {
                      title {
                        text
                      }
                      index_image {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
